// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bJ_kr";
export var caseStudyBackground__lineColor = "bJ_kp";
export var caseStudyHead = "bJ_kj";
export var caseStudyHead__imageWrapper = "bJ_kk";
export var caseStudyProjectsSection = "bJ_ks";
export var caseStudyQuote__bgRing = "bJ_km";
export var caseStudyVideo__ring = "bJ_kv";
export var caseStudy__bgDark = "bJ_kh";
export var caseStudy__bgDarkReverse = "bJ_l6";
export var caseStudy__bgLight = "bJ_kg";